/**
 * Feature Constants // Define Project constants here
 */

const features = {
    login: 'login',
    user: 'user',
    static : ''
};

export default features;
